import React from 'react'
import { View } from 'react-native'
import TrackLabels from './TrackLabels'
import { TrackMenu } from './TrackMenu'
import { trackStyles } from './TrackStyles'

const SingleTrack = ({
	track,
	tracksList,
	isTracksRemovable,
	playlistId,
	playlistTrackId,
	userPlaylists,
	setTrackList,
	navigation = undefined,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	isRepeatAllTracks = false,
	item,
	trackIndex,
	isQueue = false,
	...props
}) => {
	console.log('SingleTrack')
	return (
		<View style={trackStyles.trackRow} {...props}>
			<TrackLabels track={track} isQueue={isQueue} tracksList={tracksList} trackIndex={trackIndex} playlistTrackId={playlistTrackId} />
			
			{navigation && (
				<View style={[trackStyles.leftContainer, {marginTop: trackIndex === 0 ? 25 : 0}]}>
					<TrackMenu
						track={track}
						playlistId={playlistId}
						playlistTrackId={playlistTrackId}
						isTracksRemovable={isTracksRemovable}
						setTrackList={setTrackList}
						tracksList={tracksList}
						userPlaylists={userPlaylists}
						updateUserPlaylists={updateUserPlaylists}
						openNotifModal={openNotifModal}
						closeNotifModal={closeNotifModal}
						item={item}
					/>
				</View>
			)}
		</View>
	)
}

const Track = React.memo(SingleTrack)
export default Track
