import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Pressable, Text, TouchableHighlight, View } from 'react-native'
import PlayButton from '../../../Components/Layouts/Sidebar/components/TrackControls/PlayButton'
import truncateStr from '../../../Helpers/truncateStr'
import { colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import useSound from '../../../Hooks/useSound'
import { trackStyles } from './TrackStyles'

const TrackLabels = ({ track, isQueue, tracksList, trackIndex, playlistTrackId }) => {
	const { deviceType } = useLayout()
	const {
		handleTrackPlayer,
		currentPlayingTrack,
		handleClickRepeat,
		repeatType,
		trackList,
		setIsShuffleActive,
		isRepeatAllTracks,
		sound
	} = useSound()

	const [playing, setPlaying] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const navigation = useNavigation()
	const isTrackFileValid = track.track_file !== null && track.track_file !== undefined && track.track_file !== '' 
	
	const isFirstItem = trackIndex === 0

	const handlePressTrack = () => {
		if (!isTrackFileValid) {
			return;
		}

		if (isRepeatAllTracks && repeatType === 'none') handleClickRepeat('repeatAllTracks')
		else if (!isRepeatAllTracks && repeatType === 'repeatAllTracks') handleClickRepeat('none')
		if (isQueue) {
			let newIndex = trackList.findIndex(x => x.id === playlistTrackId)
			handleTrackPlayer(trackList[newIndex].track, trackList, newIndex)
		} else {
			if (trackList.length > 0) {
				if (!tracksList[0].playlist && !trackList[0].playlist) {
					handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
					setIsShuffleActive(false)
					return
				}
				if (tracksList[0].playlist === trackList[0].playlist) {
					let newIndex = trackList.findIndex(x => x.id === tracksList[trackIndex].id)
					handleTrackPlayer(trackList[newIndex].track, trackList, newIndex)
				} else {
					handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
					setIsShuffleActive(false)
				}
			} else {
				handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
				setIsShuffleActive(false)
			}
		}
	}

	const initialLoad = useCallback(async () => {
		setPlaying(currentPlayingTrack?.id === track?.id)
		setLoaded(currentPlayingTrack?.id === track?.id)
	}, [currentPlayingTrack?.id, track?.id])

	useEffect(() => {
		initialLoad()
	}, [currentPlayingTrack])

	const getTextColor = () => {
		if ((playing || loaded) && isTrackFileValid) {
			return colors.accent;
		} else if (!isTrackFileValid) {
			return 'gray';
		} else {
			return 'white';
		}
	}

	return (
		<View style={{ flexDirection: 'row', flex: 12 }}>
			<Pressable
				onPress={() => {
					handlePressTrack()
				}}
				style={trackStyles.songContainer}
			>
			{isFirstItem && (
				<View 
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-start',
						width: '100%',
						borderBottomColor: 'rgba(237, 231, 225, .1)',
						borderBottomWidth: 0.1,
						marginBottom: 10
					}}
				>
					<Text style={[trackStyles.trackListHeader, {marginLeft: 40, width: '40%'}]}>Titel & artist</Text>
					<Text style={[trackStyles.trackListHeader, {width: '50%'}]}>Album</Text>
				</View>
			)}
				<View style={{ flexDirection: 'row' }}>
					{navigation && (
						<View style={{ marginRight: 10 }}>
							<PlayButton size={30} track={track} handlePressTrack={handlePressTrack} />
						</View>
					)}
					<View style={{ width: '40%' }}>
						<Text
							style={{
								color: getTextColor(),
								fontSize: 14
							}}
						>
							{ trackIndex + 1 + '. ' + truncateStr(track.song_title, 45) }
						</Text>
						<TouchableHighlight
							onPress={() => {
								if (navigation)
									navigation.navigate('ArtistTracks', {
										artistId: track.artist,
										photo: track.album_photo,
										title: track.artist_name
									})
							}}
						>
							<View style={{ width: '80%' }}>
								<Text style={[trackStyles.albumTitle, { color: getTextColor() }]}>{truncateStr(track.artist_name, 45)}</Text>
							</View>
						</TouchableHighlight>
					</View>
					{deviceType !== 1 && (
						<View style={{ width: '40%' }}>
							<Pressable onPress={() => handlePressTrack()} style={trackStyles.albumContainer}>
								<TouchableHighlight
									onPress={() => {
										if (navigation)
											navigation.navigate('AlbumTracks', {
												albumId: track.album,
												photo: track.album_photo,
												title: track.album_name
											})
									}}
								>
									<View>
										<Text
											style={{
												color: getTextColor(),
												fontSize: 13
											}}
										>
											{truncateStr(track.album_name, 45)}
										</Text>
									</View>
								</TouchableHighlight>
							</Pressable>
						</View>
					)}
				</View>
			</Pressable>
		</View>
	)
}

export default React.memo(TrackLabels)
