/* -------------------------------- libraries ------------------------------- */
import { useEffect, useState } from 'react'
import { View } from 'react-native'

/* ------------------------------- components ------------------------------- */
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import RefreshButton from '../../Components/RefreshButton'

/* --------------------------------- helpers -------------------------------- */
import { captureException } from '../../Helpers/sentryLog'
import { playlistSortByName } from '../../Helpers/sortArray'
import { colors, PLAYLIST_TRACKS_EDIT_URL, PLAYLIST_TRACKS_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'

/* ---------------------------------- hooks --------------------------------- */
import useAxios from '../../Hooks/useAxios'

/* ---------------------------- local components ---------------------------- */
import useSound from '../../Hooks/useSound'
import TrackList from './components/TrackList'

export default function TracksScreen({ route, navigation, ...props }) {
	const api = useAxios()

	const [trackListState, setTrackListState] = useState([])
	const [userPlaylists, setUserPlaylists] = useState([])
	const [edit, setEdit] = useState(false)
	const {
		playlistId,
		photo,
		title,
		isTracksRemovable,
		isTrackPositionChangeable,
		hasCopyPlaylistBtn,
		musicTherapistName,
		musicTherapistId,
		copyMyPlaylistLabel,
		description
	} = route.params ? route.params : global.routeParams

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(true)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const { setTrackList, trackList, setCurrentPlayingTrackIndex, currentPlayingTrackIndex, currentPlayingTrack } = useSound()

	const handleSetTrackList = (newTracks) => {
		if (newTracks.length > 0 && trackList.length > 0) {
			if (newTracks[0].playlist === trackList[0].playlist) {
				if (currentPlayingTrack) {
					let playlistTrackId = trackList[currentPlayingTrackIndex].id
					let newCurrentTrackIndex = newTracks.findIndex(x => x.id === playlistTrackId)
					console.log(JSON.stringify(newCurrentTrackIndex))
					setCurrentPlayingTrackIndex(newCurrentTrackIndex)
					setTrackList(newTracks)
					setTrackListState(newTracks)
				}
			}
		}
	}

	const getDataFromApi = () => {
		Promise.all([USER_PLAYLIST_URL, PLAYLIST_TRACKS_URL + playlistId].map(endpoint => api.get(endpoint)))
			.then(([{ data: user }, { data: trackLists }]) => {
				user = playlistSortByName(user)
				setUserPlaylists(user)
				// addDataToUserPlaylistsTable(user)
				if (trackLists.length > 0) trackLists[0].refreshUrl = PLAYLIST_TRACKS_URL + playlistId
				setTrackListState(trackLists)
				// addDataToTracksTable(trackLists)
			})
			.catch(err => {
				console.log(err.message)
				setError(true)
				captureException(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id, name, description, visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		setError(false)

		async function boot() {
			try {
				getDataFromApi()

				// if (Platform.OS === 'web') {
				// 	getDataFromApi()
				// } else {
				// 	const { user_id } = await getUser()
				// 	const cachedTracksData = await retrieveDataFromTracksTable(playlistId)
				// 	const cachedUserData = await retrieveDataFromUserPlaylistsTable(user_id)

				// 	if (!isCachedDataValid(cachedTracksData) || !isCachedData(cachedUserData)) {
				// 		getDataFromApi()
				// 	} else {
				// 		setWasDataCached(true)
				// 		setTrackList(cachedTracksData)
				// 		setUserPlaylists(cachedUserData)
				// 		setLoading(false)
				// 	}
				// }
			} catch (error) {
				captureException(error)
				setError(true)
				setLoading(false)
			}
		}

		boot()
	}, [route.params ? route.params : global.routeParams, shouldRefresh])

	const saveTracksPosition = (newTracks) => {
		newTracks.map(async (trackItem, index) => {
			let position = index
			trackItem.position = position
			let track = trackItem.track.id
			let playlist = trackItem.playlist

			await api.put(PLAYLIST_TRACKS_EDIT_URL + trackItem.id, { position, track, playlist }).then(response => {
				if (response.status === 200) {
					console.log('Success change position...' + position + ' ' + trackItem.track.song_title)
				} else console.log('Failed change position...' + position + ' ' + trackItem.track.song_title)
			})
		})
	}

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	else
		return loading ? (
			<Loader />
		) : (
			<View style={{ backgroundColor: colors.primary, flex: 1 }}>
				<TrackList
					photo={photo}
					title={title}
					tracksList={trackListState}
					isTracksRemovable={isTracksRemovable}
					setTrackList={handleSetTrackList}
					userPlaylists={userPlaylists}
					isTrackPositionChangeable={isTrackPositionChangeable}
					navigation={navigation}
					updateUserPlaylists={updateUserPlaylists}
					openNotifModal={openNotifModal}
					closeNotifModal={closeNotifModal}
					edit={edit}
					setEdit={setEdit}
					tracks={trackListState.length}
					hasCopyPlaylistBtn={hasCopyPlaylistBtn}
					musicTherapistName={musicTherapistName}
					musicTherapistId={musicTherapistId}
					saveTracksPosition={saveTracksPosition}
					copyMyPlaylistLabel={copyMyPlaylistLabel}
					trackList={trackListState}
					isHidePlaylistDetails={false}
					description={description}
				/>
				{
					notifModal.visible && (
						<NotificationModal
							title={notifModal.title}
							description={notifModal.description}
							visible={notifModal.visible}
							closeNotifModal={closeNotifModal}
							isDisappearing={notifModal.isDisappearing}
						/>
					)
				}
			</View >
		)
}
